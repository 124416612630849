var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Contravale")])],1),_c('v-container',[_c('v-card-title',[_c('strong',[_vm._v("Contravale No: ")]),_vm._v(" "+_vm._s(_vm.voucherData.code)+" "),_c('v-spacer'),(!_vm.voucherData.is_cancel)?_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","color":"indigo"},on:{"click":function($event){return _vm.getVoucherPDF()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-printer")])],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{attrs:{"height":"100%"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('strong',[_vm._v("Empresa: ")]),_vm._v(" "+_vm._s(_vm.voucherData.company.name)+" ")]),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('strong',[_vm._v("Proveedor: ")]),_vm._v(" "+_vm._s(_vm.voucherData.provider.name)+" "),(
                            _vm.voucherData.is_editable && !_vm.voucherData.is_cancel
                          )?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.editItem(_vm.voucherData.id, 'provider')}}},[_vm._v("mdi-pencil")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('strong',[_vm._v("Número de Vale: ")]),_vm._v(" "+_vm._s(_vm.voucherData.voucher_number)+" "),(
                            _vm.voucherData.is_editable && !_vm.voucherData.is_cancel
                          )?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.editItem(_vm.voucherData.id, 'vale')}}},[_vm._v("mdi-pencil")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('strong',[_vm._v("Total:")]),_vm._v(" "+_vm._s(_vm._f("currency")(_vm.voucherData.amount))+" ")]),(
                          !_vm.voucherData.registered &&
                          !_vm.voucherData.is_cancel &&
                          !_vm.voucherData.is_editable
                        )?_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"color":"primary","depressed":"","text":"","to":{
                            name: 'accounting-payment_create',
                            query: {
                              order: _vm.voucherData.id,
                              type: 'voucher',
                            },
                          }}},[_vm._v("PAGAR")])],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('strong',[_vm._v("Fecha: ")]),_vm._v(" "+_vm._s(_vm._f("date")(_vm.voucherData.date))+" ")]),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('strong',[_vm._v("Realizó:")]),_vm._v(" "+_vm._s(_vm.voucherData.user.username)+" ")]),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('strong',[_vm._v("Solicitado por:")]),_vm._v(" "+_vm._s(_vm.voucherData.requested_by)+" ")])],1)],1)],1)],1),(_vm.voucherData.is_cancel)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-alert',{attrs:{"dense":"","type":"error"}},[_vm._v(" Este Voucher fue "),_c('strong',[_vm._v("Anulado")])])],1)],1):_vm._e()],1)],1)],1),(_vm.voucherData.is_editable && !_vm.voucherData.is_cancel)?_c('v-alert',{attrs:{"type":"info","prominent":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_vm._v(" Actualizar precios o finalizar")]),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{on:{"click":function($event){return _vm.closeVoucher()}}},[_vm._v("Finalizar")])],1)],1)],1):_vm._e(),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.voucherData.details,"hide-default-footer":"","disable-pagination":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                          var item = ref.item;
return [(_vm.voucherData.is_editable && !_vm.voucherData.is_cancel)?_c('v-icon',{staticClass:"mr-2",attrs:{"medium":"","color":"primary"},on:{"click":function($event){return _vm.addItem(true, item.id)}}},[_vm._v(" mdi-pencil")]):_vm._e(),(_vm.voucherData.is_editable && !_vm.voucherData.is_cancel)?_c('v-icon',{staticClass:"mr-2",attrs:{"medium":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v(" mdi-trash-can-outline")]):_vm._e()]}}],null,true)}),_c('v-row',[_c('v-col',[(
                _vm.isHourRange(_vm.voucherData.date, 32) &&
                _vm.voucherData.is_editable &&
                !_vm.voucherData.is_cancel
              )?_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteVoucher()}}},[_vm._v("Anular")]):_vm._e()],1),_c('v-col',{staticClass:"text-end"},[(_vm.voucherData.is_editable && !_vm.voucherData.is_cancel)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.addItem(false)}}},[_vm._v("Agregar")]):_vm._e()],1)],1)],1)],1)],1),_c('update-form',{attrs:{"title":_vm.title,"dialog":_vm.updateDialog,"label":_vm.label,"field":_vm.field,"uri":_vm.uri},on:{"close":function($event){_vm.updateDialog = false},"saved":function($event){return _vm.getVoucher()}}}),_c('add-item',{attrs:{"dialog":_vm.itemDialog,"voucherId":_vm.voucherData.id,"update":_vm.update,"item":_vm.itemId},on:{"close":function($event){(_vm.itemDialog = false), (_vm.update = false)},"saved":function($event){return _vm.getVoucher()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }