<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Contravale</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-card-title>
            <strong>Contravale No: </strong>
            {{ voucherData.code }}
            <v-spacer></v-spacer>
            <v-btn
              v-if="!voucherData.is_cancel"
              class="mx-2"
              fab
              dark
              color="indigo"
              @click="getVoucherPDF()"
            >
              <v-icon dark>mdi-printer</v-icon>
            </v-btn>
          </v-card-title>
          <v-row>
            <v-col cols="12" md="12">
              <v-card height="100%">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" md="12">
                          <strong>Empresa: </strong>
                          {{ voucherData.company.name }}
                        </v-col>
                        <v-col cols="12" md="12">
                          <strong>Proveedor: </strong>
                          {{ voucherData.provider.name }}
                          <v-icon
                            v-if="
                              voucherData.is_editable && !voucherData.is_cancel
                            "
                            small
                            class="mr-2"
                            color="primary"
                            @click="editItem(voucherData.id, 'provider')"
                            >mdi-pencil</v-icon
                          >
                        </v-col>
                        <v-col cols="12" md="12">
                          <strong>Número de Vale: </strong>
                          {{ voucherData.voucher_number }}
                          <v-icon
                            v-if="
                              voucherData.is_editable && !voucherData.is_cancel
                            "
                            small
                            class="mr-2"
                            color="primary"
                            @click="editItem(voucherData.id, 'vale')"
                            >mdi-pencil</v-icon
                          >
                        </v-col>
                        <v-col cols="12" md="6">
                          <strong>Total:</strong>
                          {{ voucherData.amount | currency }}
                        </v-col>
                        <v-col
                          class="text-center"
                          cols="12"
                          md="6"
                          v-if="
                            !voucherData.registered &&
                            !voucherData.is_cancel &&
                            !voucherData.is_editable
                          "
                        >
                          <v-btn
                            color="primary"
                            depressed
                            text
                            :to="{
                              name: 'accounting-payment_create',
                              query: {
                                order: voucherData.id,
                                type: 'voucher',
                              },
                            }"
                            >PAGAR</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" md="12">
                          <strong>Fecha: </strong>
                          {{ voucherData.date | date }}
                        </v-col>
                        <v-col cols="12" md="12">
                          <strong>Realizó:</strong>
                          {{ voucherData.user.username }}
                        </v-col>
                        <v-col cols="12" md="12">
                          <strong>Solicitado por:</strong>
                          {{ voucherData.requested_by }}
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-row v-if="voucherData.is_cancel">
                  <v-col cols="12" md="12">
                    <v-alert dense type="error">
                      Este Voucher fue <strong>Anulado</strong>
                    </v-alert>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <v-alert
            type="info"
            prominent
            v-if="voucherData.is_editable && !voucherData.is_cancel"
          >
            <v-row align="center">
              <v-col class="grow"> Actualizar precios o finalizar</v-col>
              <v-col class="shrink">
                <v-btn @click="closeVoucher()">Finalizar</v-btn>
              </v-col>
            </v-row>
          </v-alert>
          <v-divider></v-divider>
          <v-data-table
            :headers="headers"
            :items="voucherData.details"
            hide-default-footer
            disable-pagination
            disable-sort
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                v-if="voucherData.is_editable && !voucherData.is_cancel"
                medium
                class="mr-2"
                color="primary"
                @click="addItem(true, item.id)"
              >
                mdi-pencil</v-icon
              >
              <v-icon
                v-if="voucherData.is_editable && !voucherData.is_cancel"
                medium
                class="mr-2"
                color="red"
                @click="deleteItem(item.id)"
              >
                mdi-trash-can-outline</v-icon
              >
            </template>
          </v-data-table>
          <v-row>
            <v-col>
              <v-btn
                color="error"
                v-if="
                  isHourRange(voucherData.date, 32) &&
                  voucherData.is_editable &&
                  !voucherData.is_cancel
                "
                @click="deleteVoucher()"
                >Anular</v-btn
              >
            </v-col>
            <v-col class="text-end">
              <v-btn
                color="primary"
                v-if="voucherData.is_editable && !voucherData.is_cancel"
                @click="addItem(false)"
                >Agregar</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <update-form
      :title="title"
      :dialog="updateDialog"
      :label="label"
      :field="field"
      @close="updateDialog = false"
      @saved="getVoucher()"
      :uri="uri"
    ></update-form>
    <add-item
      :dialog="itemDialog"
      :voucherId="voucherData.id"
      :update="update"
      :item="itemId"
      @close="(itemDialog = false), (update = false)"
      @saved="getVoucher()"
    ></add-item>
  </v-row>
</template>

<script>
import isHoursRange from "@/utils/isHoursRange";
import requests from "@/services/requests";
import blobResponse from "@/utils/response.blob";
export default {
  components: {
    UpdateForm: () => import("@/components/forms/UpdateField"),
    AddItem: () => import("@/components/vouchers/AddItem"),
  },
  props: {
    dialog: { type: Boolean, required: true },
    voucher: { type: Number, required: true },
  },
  data() {
    return {
      itemDialog: false,
      update: false,
      itemId: undefined,
      uri: "",
      field: "",
      title: "",
      label: "",
      updateDialog: false,
      voucherData: {
        id: 0,
        requested_by: "",
        company: {
          name: "",
          business_name_id: 0,
        },
        provider: {
          id: 0,
          name: "",
        },
        provider_id: 0,
        details: [
          {
            id: 0,
            description: "",
            quantity: "",
            price: "0.00",
            amount: "0.00",
            voucher: 0,
          },
        ],
        user: {
          id: 0,
          username: "",
        },
        code: "",
        amount: "0.00",
        observations: "",
        date: "",
        is_cancel: false,
        is_paid: false,
        is_editable: true,
        section: null,
      },
      voucherUpdated: false,
      headers: [
        { text: "Descripción", value: "description" },
        { text: "Cantidad", value: "quantity" },
        { text: "Precio", value: "price" },
        { text: "Subtotal", value: "amount" },
        { text: "", value: "actions" },
      ],
    };
  },
  mounted() {
    this.getVoucher();
  },
  methods: {
    isHourRange: isHoursRange,
    deleteVoucher() {
      if (confirm("¿Está seguro de realizar esta acción")) {
        requests
          .delete("/api/vouchers/" + this.voucher + "/")
          .then((response) => {
            if (response.status == 204) {
              this.voucherUpdated = true;
              this.$emit("close", this.voucherUpdated);
            }
          });
      }
    },
    getVoucher() {
      requests.get("api/vouchers/" + this.voucher).then((response) => {
        this.voucherData = response.data;
        this.voucherUpdate = true;
      });
    },
    getVoucherPDF() {
      requests.get("/api/pdf/voucher/" + this.voucher).then((response) => {
        if (response.status == 200) {
          blobResponse(response.data, "application/pdf");
        } else {
          this.snackbarText = "Ha ocurrido un error";
          this.snackbarStatus = true;
        }
      });
    },
    editItem(item, option) {
      if (!this.voucherData.is_cancel) {
        this.updateDialog = true;
        if (option == "provider") {
          this.title = "Proveedor";
          this.label = "Proveedor";
          this.field = "provider_id";
        } else if (option == "vale") {
          this.title = "Número de Vale";
          this.label = "Vale";
          this.field = "voucher_number";
        }
        this.uri = "/api/vouchers/" + item + "/";
      }
    },
    addItem(action, item = undefined) {
      this.itemDialog = true;
      this.update = action;
      this.itemId = item;
    },
    deleteItem(item) {
      if (confirm("¿Está seguro de realizar esta acción?")) {
        requests.delete("/api/detail-vouchers/" + item).then((res) => {
          if (res.status == 204) {
            this.$toasted.global.info({
              message: "Operación realizada con éxito",
            });
            this.getVoucher();
          }else{
            console.log(res.data)
            this.$toasted.global.info({
              message: res.data.detail,
            });
          }
        });
      }
    },
    closeVoucher() {
      requests
        .patch("/api/vouchers/" + this.voucher + "/", {
          is_editable: false,
        })
        .then((response) => {
          if (response.status == 200) {
            this.$toasted.global.info({
              message: response.details,
            });
            this.getVoucher();
          } else if (response.status == 400) {
            this.$toasted.global.info({
              message: response.data.detail,
            });
          }
        });
    },
    close() {
      this.$emit("close", this.voucherUpdate);
      this.voucherData = {
        id: 0,
        requested_by: "",
        company: {
          name: "",
          business_name_id: 0,
        },
        provider: {
          id: 0,
          name: "",
        },
        provider_id: 0,
        details: [
          {
            id: 0,
            description: "",
            quantity: "",
            price: "0.00",
            amount: "0.00",
            voucher: 0,
          },
        ],
        user: {
          id: 0,
          username: "",
        },
        code: "",
        amount: "0.00",
        observations: "",
        date: "",
        is_cancel: false,
        is_paid: false,
        is_editable: true,
        section: null,
      };
    },
  },
};
</script>